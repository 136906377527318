import styled from 'styled-components';

const ArticleCategories = styled.div`
    position: relative;

    h4 {
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        text-transform: uppercase;
        font-size: 19px;
        line-height: 30px;
        color: ${({ theme }) => theme.colors.darkGrey1};
        letter-spacing: 1.8px;
        margin-top: 0;
        margin-bottom: 10px;
        pointer-events: none;

        svg {
            display: none;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            pointer-events: all;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey1};
            padding-bottom: 20px;
            
            svg {
                display: block;
            }
        }
    }

    a {
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 0.3px;
        text-decoration: none;
        display: block;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            display: none;
        }
    }
`
const SlidingModal = styled.div`
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white1};
    width: 100%;
    height: 100%;
    transition: transform .5s ease;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    z-index: 9999;
    padding: 30px;
    display: flex;
    flex-direction: column;

    button {
        position: relative;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        width: 30px;
        height: 30px;
        padding: 0;
        margin-left: auto;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .categories {
        margin-top: 60px;

        h4 {
            text-transform: uppercase;
            letter-spacing: 1.8px;
            margin-bottom: 0;
        }

        a, h4 {
            border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey2};
            padding: 10px 0;
            display: block;
        }
    }
`

export default {
    ArticleCategories,
    SlidingModal
}