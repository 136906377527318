import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface CloseIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const CloseIcon = ({ colourScheme, color, ...rest }: CloseIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.CloseIcon
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      {...rest}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="square"
        strokeOpacity="0.85"
        strokeWidth="1"
      >
        <g
          stroke={color || strokeColour}
          strokeWidth="1.5"
          transform="translate(-901 -38)"
        >
          <g transform="matrix(-1 0 0 1 942 39)">
            <path
              d="M-6.299 20.356L47.044 19.644"
              transform="rotate(135 20.372 20)"
            ></path>
            <path
              d="M-6.671 20.027L46.671 20.738"
              transform="rotate(45 20 20.382)"
            ></path>
          </g>
        </g>
      </g>
    </S.CloseIcon>
  );
};

export default CloseIcon;
